import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import { Header } from '../components/Header';
import Content, { HTMLContent } from '../components/Content'
import { Link } from 'react-router-dom';
import { CartProducts } from '../components/CartProducts';
import { CartContextConsumer } from '../contexts/CartContext';

export const CartPageTemplate = ({ title, image, subheading, content, contentComponent }) => {
  const PageContent = contentComponent || Content

  const handleCheckout = () => {};
  const clearCart = () => {};

  // const { cart } = useContext(CartContext);


  return (
    <div>
      <Header title={title} subheading={subheading} image={image} />
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="section">
              <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
                {title}
              </h2>
              <PageContent className="content" content={content} />
              <CartContextConsumer>
              {context => (

              
              <div className="row no-gutters justify-content-center">
                    <div className="col-sm-9 p-3">
                        {
                            context.totalItems > 0 ?
                            <CartProducts /> :
                            <div className="p-3 text-center text-muted">
                                Your cart is empty
                            </div>
                        }

                        { false /* checkout */ && 
                            <div className="p-3 text-center text-success">
                                <p>Checkout successfull</p>
                                <Link to="/" className="btn btn-outline-success btn-sm">BUY MORE</Link>
                            </div>
                        }
                    </div>
                    {
                        context.items.length > 0 && 
                        <div className="col-sm-3 p-3">
                            <div className="card card-body">
                                <p className="mb-1">Total Items</p>
                                <h4 className=" mb-3 txt-right">{context.totalItems}</h4>
                                <p className="mb-1">Total Payment</p>
                                <h3 className="m-0 txt-right">{context.totalCost}</h3>
                                <hr className="my-4"/>
                                <div className="text-center">
                                    <button type="button" className="btn btn-primary mb-2" onClick={() => handleCheckout()}>CHECKOUT</button>
                                    <button type="button" className="btn btn-outlineprimary btn-sm" onClick={() => clearCart()}>CLEAR</button>
                                </div>

                            </div>
                        </div>
                    }
                    
                </div>
                )}
                </CartContextConsumer>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

CartPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string.isRequired,
  subheading: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const CartPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <CartPageTemplate
        image={post.frontmatter.image}
        subheading={post.frontmatter.subheading}
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
      />
    </Layout>
  )
}

CartPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default CartPage

export const cartPageQuery = graphql`
  query CartPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        subheading
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
