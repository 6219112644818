import React from 'react';
import { CartContextConsumer } from '../contexts/CartContext';
import { formatPrice } from './NumberFormatting';

const CartItem = ({product}) => {

    // const { cart } = useContext(CartContext);
    //const forceUpdate = useForceUpdate();
    //cart.addListener(() => {
    //    forceUpdate();
    //});
    // const forceUpdate = useForceUpdate();

    return (
        <CartContextConsumer>
            {context => (
        <div className="row no-gutters py-2">
            <div className="col-sm-2 p-2">
                <img
                alt={product.name}
                style={{margin: "0 auto", maxHeight: "50px"}} 
                src={product.photo} className="img-fluid d-block"/>
            </div>
            <div className="col-sm-4 p-2">
                <h5 className="mb-1">{product.name}</h5>
                <p className="mb-1">Price: {formatPrice(product.price)} </p>
                
            </div>
            <div className="col-sm-2 p-2 text-center ">
                 <p className="mb-0">Qty: {product.quantity}</p>
            </div>
            <div className="col-sm-4 p-2 text-right">
                 <button 
                 onClick={() => { console.log('onclick-increase'); context.increaseItem(product); }}
                 className="btn btn-primary btn-sm mr-2 mb-1">
+
                 </button>

                 {
                     product.quantity > 1 &&
                     <button
                    onClick={() => { console.log('onclick-decrease'); context.decreaseItem(product);  }}
                    className="btn btn-danger btn-sm mb-1">
-
                    </button>
                 }

                {
                     product.quantity === 1 &&
                     <button
                    onClick={() => { console.log('onclick-remove'); context.removeItem(product); }}
                    className="btn btn-danger btn-sm mb-1">
DEL
                    </button>
                 }
                 
            </div>
        </div>
        )}
        </CartContextConsumer>
     );
}
 
export default CartItem;