import React from 'react';
import { CartContextConsumer } from '../contexts/CartContext';

import CartItem from './CartItem';
import styles from './CartProducts.module.scss';

export const CartProducts = ({ cartItems }) => {

    // const { cart } = useContext(CartContext);

    return ( 
        <div className={styles.p__container}>
            <CartContextConsumer>
                {context => (
                    <div className="card card-body border-0">
                    {
                        context.items.map(product =>  <CartItem key={product.sku} product={product}/>)
                    }
                    </div>
                )}
            </CartContextConsumer>
        </div>

     );
}
 
export default CartProducts;